import React from "react";
import "./Services.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <>
      <br />
      <br />
      <br />

      <div className="service-all">
        <div className="s-header">
          <h2 style={{ fontFamily: "" }}>
            Our <span style={{ color: "green" }}>Services</span>
          </h2>
          <span className="max">
            <p>
              We are renowned name in real estate advisory services. What drive
              us towards our clients/ landlords and our associates is our
              Relation, Satisfaction, Commitment, and Trust. Our Services
              include end to end deliverables to our clients by searching,
              suggesting suitable property.
            </p>
          </span>
        </div>

        <div className="s-all">
          <div className="s-1">
            

            <h5>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Sale Of BDA Layouts
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Second Sales
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Marketing Services
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Construction
            </h5>
           <br/><br/>
            <a href="/Service1">
              <button className="s-button">Services</button>
            </a>
          </div>

          <div className="s-1">
            

            <h5>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Liaison Work
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              House Construction
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Property Investment
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Co-Work Spaces
            </h5>
            <br/>
            <br/>
            <a href="/Service1">
              <button className="s-button">Services</button>
            </a>
          </div>

          <div className="s-1">
            

            <h5>
              <FontAwesomeIcon
                className="fai"
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Transparent Deals
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                className="fai"
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Appreciation Potential
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                className="fai"
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Proffessional Services
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                className="fai"
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              commercial Spaces
            </h5>
            <br />
            <h5>
              <FontAwesomeIcon
                className="fai"
                icon={faArrowRight}
                style={{ color: "#25ad34" }}
              />{" "}
              Asset Management Company
            </h5>


            <a href="/Service1">
              <button className="s-button">Services</button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
