import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import Header from './Components/header/Header';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Footer2 from './Components/footer/Footer';



import Contact2 from './pages/contact2/Contact2';

import Service2 from './pages/services2/Service2';
import Project2 from './pages/project2/Project2';

function App() {
  return (
    <div >
<BrowserRouter>
     <Header/>
        <Routes>
        <Route path='/' element={<Home />} />
         <Route path='/Service1' element={<Service2 />} />
         <Route path='/Projects' element={<Project2 />} />
        
        <Route path='/contact' element={<Contact2 />} />
        
      


        </Routes>
       
     
     
     </BrowserRouter>
    <Footer2/>
    </div>
  );
}

export default App;
