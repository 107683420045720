import React from 'react'
import './Home.css'

import About from './about/About'



import { Choose } from './choose/Choose'
import Footer1 from './Footer1/Footer1'

import Services from './Services/Services'

import Projects from './projects/Projects'
import Contact from './contacts/Contact'




const Home = () => {
  return (
    <>
  
    <div className='ah'>
        <div className='intro'>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            
           
          
        <h1> 
        Your Trusted Partner<br/><span className='hi' > In Real Estate Excellance</span> 
              <div className='hb-all'>
                <a href='/About'> <button className='h-button'>Read More</button></a>
           <a  href='/Contact'>  <button className='h-button'>Contact Us</button></a>
            

            </div>
        </h1>
        </div>
        </div>
      
        <About/>
       
        <br/>
        
        <Projects/>
        <Services/>
        <Choose/>
        <Footer1/>
        <Contact/>
      
        
        </>
  )
}

export default Home