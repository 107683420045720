import React from 'react';
import './About.css';
import about from '../../Assets/about.jpg'



const About = () => {
  return (
    <>
    <div className='a-all'>
        <div className='a-image'>
            <div className='g-container'></div>
            <img src={about} />

        </div>

        <div className='a-text'>
             <h1>About Us</h1>
             <p>
            

AG Founder Mr. M.A. Kumar, with three decades of experience in the real estate field, leads a company that excels in comprehensive documentation and liaison with government agencies such as Bescom, BDA, BMRDA, BBMP, and others. We ensure impeccable legal documentation for around 150 approved layouts across Bangalore. Our services include land conversion, change of land use, and planning approvals for villas, apartments, and layouts. We are venturing into affordable house construction and marketing in Bangalore and tier-2 cities of Karnataka, supported by top-tier digital marketing professionals. Our land procurement spans five districts: Bangalore Urban, Rural, Chikkaballapur, Kolar, and Ramnagar, catering to corporate companies, real estate firms, and individuals. Our vision is to provide proper guidance in real estate, making the dream of owning a site or house a reality for the common man.
                

             </p>
           
             <a href='/Contact'><button className='h-button'>Contact Us</button></a>
             
               <br/>
               <br/>
            
        </div>
    </div>
    </>
  )
}

export default About